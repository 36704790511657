







import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import PromoTable from '@/components/tables/PromoTable.vue'

@Component({
  components: {
    PromoTable,
  },
})
export default class OfferList extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Промоакции',
    }
  }
}
