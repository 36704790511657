





























































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import { tableFooterOptions } from '@/utils/constants'
import ManagerPromotionModule from '@/store/modules/manager/offer'
import { IPromoFilter, PromotionShortResource } from '@/store/types'
import TableFooter from '@/components/_uikit/TableFooter.vue'

@Component({
  components: {
    DateInput,
    TableFooter,
    TextInput,
  },
})
export default class PromoTable extends Mixins(NotifyMixin) {
  private footerOptions = tableFooterOptions

  private get headers () {
    return [
      { sortable: false, text: '#', value: 'id' },
      { text: 'Название', value: 'name' },
      { text: 'Код', value: 'code' },
      { text: 'Количество', value: 'count' },
      { text: 'Дата проведения', value: 'date' },
    ]
  }

  private get promotions () {
    return ManagerPromotionModule.promotions.data
  }

  private get pagination () {
    return ManagerPromotionModule.promotions.meta
  }

  private get filter () {
    return ManagerPromotionModule.promoFilter
  }

  private set filter(filter: IPromoFilter) {
    if (!isEqual(filter, this.filter) || this.promotions.length === 0) {
      ManagerPromotionModule.setPromoFilter(filter)
      this.fetchPromotions()
    }
  }

  @Bind
  @Debounce(300)
  private fetchPromotions () {
    ManagerPromotionModule.fetchPromotions()
      .catch(this.notifyError)
  }

  private handleFilter(field: string, value: any) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private handleRowClick (item: PromotionShortResource) {
    this.$router.push({
      name: 'manager.commerce.promocodes.edit',
      params: { promoId: item.id.toString() },
    })
  }
}
